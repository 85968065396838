import { Button, Tag } from "antd";
export const Users = (handleUpdateToken, hanleStop) => [
  {
    title: "SL",
    dataIndex: "username",
    key: "username",
    width: "5%",
    render: (id, _, idx) => idx + 1,
  },
  {
    title: "Username",
    dataIndex: "username",
    key: "username",
    width: "10%",
    render: (data) => data.toUpperCase(),
  },
  {
    title: "UserId",
    dataIndex: "userId",
    width: "10%",
  },
  {
    title: "MaxProfit",
    dataIndex: "maxProfit",
    width: "10%",
    render: (data) => {
      return !isNaN(data) ? (
        <h4 style={{ color: "green" }}>
          {Number(+data.toFixed(2)).toLocaleString("en-IN") || "-"}
        </h4>
      ) : (
        <h4>{"-"}</h4>
      );
    },
  },
  {
    title: "MaxLoss",
    dataIndex: "maxLoss",
    width: "10%",
    render: (data) => {
      return !isNaN(data) ? (
        <h4 style={{ color: "red" }}>
          {Number(+data.toFixed(2)).toLocaleString("en-IN") || "-"}
        </h4>
      ) : (
        <h4>{"-"}</h4>
      );
    },
  },
  {
    title: "P&l",
    dataIndex: "total",
    width: "10%",
    render: (data) => {
      return !isNaN(data) ? (
        <h4 style={{ color: data >= 0 ? "green" : "red" }}>
          {Number(+data.toFixed(2)).toLocaleString("en-IN") || "-"}
        </h4>
      ) : (
        <h4>{"-"}</h4>
      );
    },
  },
  {
    title: "Current Status",
    dataIndex: "isLive",
    key: "isLive",
    width: "10%",
    render: (data) => {
      return data ? (
        <Tag color="green">online</Tag>
      ) : (
        <Tag color="red">Offline</Tag>
      );
    },
  },

  {
    title: "Update Token",
    dataIndex: "isLive",
    key: "isLive",
    width: "10%",
    render: (data, item) => {
      return (
        <Button
          onClick={() => {
            handleUpdateToken(item);
          }}
        >
          Update Token
        </Button>
      );
    },
  },
  {
    title: "Stop Trade",
    dataIndex: "isLive",
    key: "isLive",
    width: "10%",
    render: (data, item) => {
      return (
        <Button
          disabled={!data}
          onClick={() => {
            hanleStop(item);
          }}
        >
          Stop Trade
        </Button>
      );
    },
  },
];

export const StocksColumn = [
  {
    title: "SL",
    dataIndex: "name",
    key: "name",
    width: "5%",
    render: (id, _, idx) => idx + 1,
  },
  {
    title: "Name",
    dataIndex: "symbol",
    key: "symbol",
    width: "10%",
    render: (data) => data.toUpperCase(),
  },
  {
    title: "StockId",
    dataIndex: "stockId",
    width: "10%",
  },
  {
    title: "Direction",
    dataIndex: "direction",
    width: "10%",
    render: (data) => {
      return data === "BUY" ? (
        <Tag color="green">BUY</Tag>
      ) : data === "SELL" ? (
        <Tag color="red">SELL</Tag>
      ) : (
        <Tag color="orange">No Trade</Tag>
      );
    },
  },
  {
    title: "Upper Limit",
    dataIndex: "upperLimit",
    width: "10%",
  },
  {
    title: "Lower Limit",
    dataIndex: "lowerLimit",
    width: "10%",
  },
  {
    title: "Status",
    dataIndex: "status",
    width: "10%",
    render: (data) => {
      return data ? (
        <Tag color="green">{data}</Tag>
      ) : (
        <Tag color="orange">TRIGGER PENDING</Tag>
      );
    },
  },
];

export const UsersColumn = [
  {
    title: "SL",
    dataIndex: "username",
    key: "username",
    width: "5%",
    render: (id, _, idx) => idx + 1,
  },
  {
    title: "Username",
    dataIndex: "username",
    key: "username",
    width: "10%",
    render: (data) => data.toUpperCase(),
  },
  {
    title: "UserId",
    dataIndex: "userId",
    width: "10%",
  },
  {
    title: "Current Status",
    dataIndex: "isLive",
    key: "isLive",
    width: "10%",
    render: (data) => {
      return data ? (
        <Tag color="green">online</Tag>
      ) : (
        <Tag color="red">Offline</Tag>
      );
    },
  },
];

export const OrdersColumn = (handleDelete) => [
  {
    title: "SL",
    dataIndex: "username",
    key: "username",
    width: "5%",
    render: (id, _, idx) => idx + 1,
  },
  {
    title: "Name",
    dataIndex: "symbol",
    key: "symbol",
    width: "10%",
    render: (data) => data.toUpperCase(),
  },

  {
    title: "Direction",
    dataIndex: "order",
    width: "10%",
    render: (data) => {
      return data === "BUY" ? (
        <Tag color="green">BUY</Tag>
      ) : data === "SELL" ? (
        <Tag color="red">SELL</Tag>
      ) : (
        <Tag color="orange">No Trade</Tag>
      );
    },
  },
  {
    title: "Expiry",
    dataIndex: "expiry",
    width: "10%",
  },
  {
    title: "Qunatity",
    dataIndex: "quantity",
    width: "10%",
    render: (data) => {
      return <Tag color="blue">{data}</Tag>;
    },
  },

  {
    title: "Type",
    dataIndex: "type",
    width: "10%",
  },
  {
    title: "Stop Loss",
    dataIndex: "sl",
    width: "10%",
  },
  {
    title: "Variance",
    dataIndex: "variance",
    width: "10%",
    render: (data, arr) => {
      return (
        <Tag color="#f76868">
          {data}-{arr.type}
        </Tag>
      );
    },
  },
  {
    title: "ONLINE STATUS",
    dataIndex: "isLiveToTrade",
    key: "isLiveToTrade",
    width: "10%",
    render: (data) => {
      return data ? (
        <Tag color="green">Live</Tag>
      ) : (
        <Tag color="red">Offline</Tag>
      );
    },
  },
  {
    title: "Shift Price",
    dataIndex: "shiftPrice",
    width: "10%",
    render: (data) => {
      return <Tag color="orange">{data?.toFixed(2) || "-"}</Tag>;
    },
  },
  {
    title: "Current Status",
    dataIndex: "status",
    width: "12%",
    render: (data) => {
      return (
        <Tag
          color={
            data === "PENDING"
              ? "blue"
              : data === "TRIGGER PENDING" || data === "REJECTED"
              ? "red"
              : data === "IGNORE_TRACKING"
              ? "purple"
              : "green"
          }
        >
          {data}
        </Tag>
      );
    },
  },

  {
    title: "Action",
    dataIndex: "isLive",
    key: "isLive",
    width: "10%",
    render: (data, item) => {
      return (
        <Button
          onClick={() => {
            handleDelete(item);
          }}
        >
          Delete
        </Button>
      );
    },
  },
];
