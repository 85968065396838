import { Table, Select, Modal, InputNumber, Input, Button } from "antd";
import { useEffect, useState } from "react";
import {
  fetchOrders,
  fetchUsers,
  createOrders,
  deleteOrders,
} from "./../API/dispatch";
import { UsersColumn, OrdersColumn } from "./../helpers/columns";

export function Orders({ ...props }) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [orders, SetOrders] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [userId, setUserId] = useState("");
  const [orderType, setOrderType] = useState("SELL");
  const [leg, setLeg] = useState("CE");
  const [sl, setSl] = useState(0);
  const [target, setTarget] = useState(0);
  const [quantity, setQuantity] = useState(0);
  const [variance, setVariance] = useState(0);
  const [expiry, setExpiry] = useState("23D08");

  const [niftyexpiry, _setNiftyExpiry] = useState("23D07");
  const [stock, setStock] = useState("BANKNIFTY");

  useEffect(() => {
    (async function fetch() {
      setLoading(true);
      const data = await fetchUsers({});
      if (data) {
        setData(data);
        setLoading(false);
      }
      setLoading(false);
    })();
  }, []);

  const handleClick = async (record) => {
    const { _id } = record;
    setLoading(true);
    const data = await fetchOrders(_id);
    if (data) {
      setData(data);
      setLoading(false);
      setUserId(_id);
    }
    SetOrders(true);
    setLoading(false);
  };

  const handleBack = async () => {
    const data = await fetchUsers({});
    if (data) {
      setData(data);
      setLoading(false);
    }
    setLoading(false);
    SetOrders(false);
  };

  const handleDelete = async (item) => {
    Modal.confirm({
      title: "Delete Order",
      content: "Are you sure delete Order " + item._id,
      okText: "ok",
      cancelText: "Cancel",
      onOk: async () => {
        await deleteOrders(item._id);
        handleClick({ _id: userId });
      },
    });
  };

  const resetState = () => {
    setOrderType("SELL");
    setLeg("CE");
    setSl(0);
    setTarget(0);
    setQuantity(0);
    setVariance(0);
    setStock(stock);
  };
  return (
    <div
      className="project-container"
      style={{ maxHeight: "100vh", background: "white" }}
    >
      <h1>ORDER MANAGEMENT</h1>
      {!orders && (
        <Table
          pagination={false}
          className={"table"}
          sticky={true}
          rowKey={"_id" || "username"}
          columns={UsersColumn}
          dataSource={data}
          scroll={{ y: true }}
          bordered={true}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => handleClick(record),
            };
          }}
          loading={loading}
        />
      )}

      {orders && (
        <>
          <div className="dropDown">
            <Select
              defaultValue="BANKNIFTY"
              style={{ width: 120 }}
              onChange={(e) => {
                setStock(e);
              }}
              options={[
                { value: "BANKNIFTY", label: "BANKNIFTY" },
                { value: "NIFTY", label: "NIFTY" },
                { value: "FINNIFTY", label: "FINNIFTY" },
                { value: "SENSEX", label: "SENSEX" },
              ]}
            />
            <Button onClick={() => setShowModal(true)}>Add Orders</Button>
            <Input
              placeholder={"Enter Expiry"}
              style={{ width: "100px" }}
              onChange={(e) => {
                setExpiry(e.target.value);
              }}
            />
          </div>

          <Table
            pagination={false}
            className={"table"}
            sticky={true}
            rowKey={"_id" || "username"}
            columns={OrdersColumn(handleDelete)}
            dataSource={data.filter((i) => i.symbol === stock)}
            scroll={{ x: 2000, y: 680 }}
            bordered={true}
            onRow={(record, rowIndex) => {
              return {
                onDoubleClick: (event) => handleBack(),
              };
            }}
            loading={loading}
          />
        </>
      )}
      <Modal
        title={"Create new Order  "}
        open={showModal}
        destroyOnClose
        onOk={async () => {
          await createOrders({
            symbol: stock,
            order: orderType,
            type: leg,
            sl,
            variance,
            quantity,
            target,
            expiry,
            userId,
          });
          setShowModal(false);
          handleClick({ _id: userId });
          resetState();
        }}
        onCancel={() => setShowModal(false)}
      >
        <h1>{stock}</h1>
        <div className="orderForm">
          <Select
            defaultValue="SELL"
            style={{ width: 120 }}
            onChange={(e) => {
              setOrderType(e);
            }}
            options={[
              { value: "BUY", label: "BUY" },
              { value: "SELL", label: "SELL" },
            ]}
          />
          <Select
            defaultValue="CE"
            style={{ width: 120 }}
            onChange={(e) => {
              setLeg(e);
            }}
            options={[
              { value: "CE", label: "CE" },
              { value: "PE", label: "PE" },
            ]}
          />

          <InputNumber
            min={0}
            placeholder="Variance"
            onChange={(e) => setVariance(e)}
          />
          <InputNumber
            placeholder="Qunatity"
            onChange={(e) => setQuantity(e)}
          />

          <InputNumber placeholder="SL" onChange={(e) => setSl(e)} />
          <InputNumber placeholder="Tartget" onChange={(e) => setTarget(e)} />
        </div>
      </Modal>
    </div>
  );
}

export default Orders;
