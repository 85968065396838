import { Table } from "antd";
import { useEffect, useState } from "react";
import { fetchStocks } from "./../API/dispatch";
import { StocksColumn } from "./../helpers/columns";

export function Stocks({ ...props }) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async function fetch() {
      setLoading(true);
      const data = await fetchStocks({});
      if (data) {
        setData(data);
        setLoading(false);
      }
      setLoading(false);
    })();
  }, []);

  return (
    <div
      className="project-container"
      style={{ maxHeight: "100vh", background: "white" }}
    >
      <Table
        pagination={false}
        className={"table"}
        sticky={true}
        rowKey={"_id" || "username"}
        columns={StocksColumn}
        dataSource={data}
        scroll={{ y: true }}
        bordered={true}
        loading={loading}
      />
    </div>
  );
}

export default Stocks;
