import {
  UploadOutlined,
  UserOutlined,
  VideoCameraOutlined,
  SettingOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import { Layout, Menu } from "antd";
import React, { useEffect, useState } from "react";
import Report from "./reports";
import Stocks from "./stocks";
import Orders from "./orders";
import "./style/project.scss";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const { Sider, Content } = Layout;

export const Users = ({ setAuth }) => {
  const [startDate, setStartDate] = useState(
    dayjs().subtract(1, "year").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(dayjs().format("YYYY-MM-DD"));
  const props = { startDate, endDate, setEndDate, setStartDate };
  const [key, setKey] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    setKey("1");
  }, []);
  const handleLogout = (event) => {
    setKey(event.key);
    if (event.key === "4") {
      localStorage.removeItem("token");
      setAuth(false);
    }
    switch (event.key) {
      case "1":
        navigate("../ai/users");
        return;
      case "2":
        navigate("../ai/stocks");
        return;
      case "3":
        navigate("../ai/orders");
        return;
    }
  };
  const { type } = useParams();

  const getContainer = () => {
    switch (type) {
      case "users":
        return <Report setAuth={setAuth} {...props} />;
      case "stocks":
        return <Stocks setAuth={setAuth} {...props} />;
      case "orders":
        return <Orders setAuth={setAuth} {...props} />;
    }
  };
  return (
    <>
      <div className="header">
        <h1>StockBot (Stocks)</h1>
      </div>
      <Layout
        style={{ height: "100vh", marginTop: "63px", background: "white" }}
      >
        <Sider trigger={null} collapsible>
          <Menu
            theme="dark"
            mode="inline"
            defaultSelectedKeys={["1"]}
            onClick={handleLogout}
            items={[
              {
                key: "1",
                icon: <UserOutlined />,
                label: "Users",
              },

              {
                key: "2",
                icon: <UploadOutlined />,
                label: "Stocks",
                disabled: true,
              },
              {
                key: "3",
                icon: <SettingOutlined />,
                label: "Orders",
              },
              {
                key: "5",
                icon: <VideoCameraOutlined />,
                label: "Live Pl",
                disabled: true,
              },
              {
                key: "4",
                icon: <LogoutOutlined />,
                label: "Logout",
                style: { background: "lightred" },
              },
            ]}
          />
        </Sider>
        <Layout className="site-layout">
          <Content>{getContainer(key)}</Content>
        </Layout>
      </Layout>
    </>
  );
};
export default Users;
