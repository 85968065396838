import axios from "axios";
import { message } from "antd";
export const login = async (url, body, setLoading) => {
  return await axios
    .post(process.env.REACT_APP_BASE_URL + url, body)
    .then((res) => {
      const { data } = res;
      localStorage.setItem("token", data.token);
      setLoading(false);
      return { success: "Server" };
    })
    .catch((err) => {
      setLoading(false);
      if (err.code === "ERR_NETWORK") {
        message.error("Server is available only between 8:45am to 3:45pm!");
        return;
      }
      message.error(err?.response?.data?.message);
    });
};

export const verify = async () => {
  return await axios
    .get(process.env.REACT_APP_BASE_URL + "/users/list", {
      headers: {
        authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
    .then(() => true)
    .catch(() => false);
};

export const listKeys = async () => {
  return await axios
    .get(process.env.REACT_APP_BASE_URL + "/users/list", {
      headers: {
        authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
    .then((res) => {
      const { data } = res;
      return data;
    })
    .catch((err) => {
      // message.error(err.response.data.message);
    });
};

export const fetchUsers = async (params) => {
  return await axios
    .get(process.env.REACT_APP_BASE_URL + "/users/list", {
      headers: {
        authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
    .then((res) => {
      const { data } = res;
      return data;
    })
    .catch((err) => {
      // message.error(err.response.data.message);
    });
};

export const fetchStocks = async () => {
  return await axios
    .get(process.env.REACT_APP_BASE_URL + "/stocks/list", {
      headers: {
        authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
    .then((res) => {
      const { data } = res;
      return data;
    })
    .catch((err) => {
      // message.error(err.response.data.message);
    });
};

export const fetchOrders = async (id) => {
  return await axios
    .post(
      process.env.REACT_APP_BASE_URL + "/users/list/orders",
      { id },
      {
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      message.error("Token is incorrect");
    });
};

export const deleteOrders = async (id) => {
  return await axios
    .post(
      process.env.REACT_APP_BASE_URL + "/users/order/delete",
      { id },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
      message.error("Unable to Delete the orders!");
    });
};
export const createOrders = async (data) => {
  return await axios
    .post(process.env.REACT_APP_BASE_URL + "/users/orders/create", data, {
      headers: {
        authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
      message.error("Error while creating the Orders!");
    });
};

export const updateToken = async (data) => {
  return await axios
    .post(
      process.env.REACT_APP_BASE_URL + "/users/token",
      { ...data },
      {
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
    .then((res) => {
      message.success("Token is updated...!");
    })
    .catch((err) => {
      message.error("Token is incorrect");
    });
};

export const stopTrade = async (data) => {
  return await axios
    .post(
      process.env.REACT_APP_BASE_URL + "/users/stop/trade/id",
      { ...data },
      {
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
    .then((res) => {
      message.success("Stop trade is updated...!");
    })
    .catch((err) => {
      message.error("Unable to Stop!");
    });
};

export const stopAllTrade = async () => {
  return await axios
    .get(process.env.REACT_APP_BASE_URL + "/users/stop/trade", {
      headers: {
        authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
    .then((res) => {
      message.success("Stop trade is updated...!");
    })
    .catch((err) => {
      message.error("Unable to Stop!");
    });
};

export const clossAllOpenPostions = async () => {
  return await axios
    .get(process.env.REACT_APP_BASE_URL + "/users/close/openorders", {
      headers: {
        authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
    .then((res) => {
      message.success("Stop trade is updated...!");
    })
    .catch((err) => {
      message.error("Unable to Stop!");
    });
};

export const resetAndStart = async () => {
  return await axios
    .get(process.env.REACT_APP_BASE_URL + "/users/start/trade", {
      headers: {
        authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
    .then((res) => {
      message.success("Reset and Start Trade Initiated!!!!!");
    })
    .catch((err) => {
      message.error("Unable to Stop!");
    });
};

export const autoLoginAllUsers = async () => {
  return await axios
    .get(process.env.REACT_APP_BASE_URL + "/users/autologin", {
      headers: {
        authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
    .then((res) => {
      message.success("Auto Login Initates!");
    })
    .catch((err) => {
      message.error("Unable to Login!");
    });
};
